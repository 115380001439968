import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Hero = () => {
  const breakpoints = useBreakpoint();

  const popInsiderUrl = "https://thepopinsider.com/products/crabs-in-a-bucket/";

  return (
    <header className="">
      {breakpoints.sm ? (
        <div className="relative">
          <StaticImage
            src="../../images/hero-mobile.png"
            alt="Crabs in a Bucket box art"
          />
          <div className="badge-mask translate-x-[105%] animate-slide-in-right absolute bottom-[1%] right-[1%] w-[35%]">
            <a
              className="block"
              href={popInsiderUrl}
              target="_blank"
              rel="noopener norefferer"
            >
              <StaticImage
                src="../../images/popinsider.png"
                placeholder="none"
              />
              <div className="animate-shimmer -translate-x-full absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[rgba(0,0,0,0)] from-40% via-[rgba(243,244,220,0.5)] via-50% to-[rgba(0,0,0,0)] to-60%"></div>
            </a>
          </div>
        </div>
      ) : (
        <div className="relative">
          <StaticImage
            src="../../images/hero-desktop.png"
            alt="Crabs in a Bucket box art"
          />
          <div className="badge-mask -translate-y-[105%] animate-slide-in-top absolute top-[1%] right-[22.5%] w-[15%]">
            <a
              className="block"
              href={popInsiderUrl}
              target="_blank"
              rel="noopener norefferer"
            >
              <StaticImage
                src="../../images/popinsider.png"
                placeholder="none"
              />
              <div className="animate-shimmer -translate-x-full absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[rgba(0,0,0,0)] from-40% via-[rgba(243,244,220,0.5)] via-50% to-[rgba(0,0,0,0)] to-60%"></div>
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Hero;
